import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { Section, TextContainer } from "../styles/container"
import { H1 } from "../styles/headings"
import { LongText } from "../styles/text"

const query = graphql`
  {
    text: airtableTexts(data: { id: { eq: "impressum" } }) {
      data {
        headline
        text1 {
          childMdx {
            rawBody
          }
        }
      }
    }
  }
`
const ImpressumPage = () => {
  const data = useStaticQuery(query)
  const {
    headline,
    text1: {
      childMdx: { rawBody: text },
    },
  } = data.text.data
  return (
    <Layout>
      <Seo title={headline} />
      <Section paddingTop paddingBottom fluid boxed>
        <TextContainer>
          <LongText>
            <H1>{headline}</H1>
            <ReactMarkdown>{text}</ReactMarkdown>
          </LongText>
        </TextContainer>
      </Section>
    </Layout>
  )
}

export default ImpressumPage
